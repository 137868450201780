/* General Footer Styling */
footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #333; /* Background color */
  color: white; /* Text color */
  padding: 1px 0;
  z-index: 1000;
}

/* Footer Content Wrapper */
.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Spread content across the footer */
  width: 90%; /* Limit content width */
  margin: 0 auto; /* Center the footer content */
}

/* Footer Logo Button Styling */
.footer-logo-button {
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Footer Logo Image Styling */
.footer-logo-image {
  width: 50px; /* Adjust logo size */
  height: auto; /* Maintain aspect ratio */
}

/* Hover Effect for Footer Logo */
.footer-logo-button:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

/* Footer Text Styling */
footer p {
  margin: 0;
  font-size: 0.9rem;
  color: #ccc; /* Slightly lighter text for contrast */
  text-align: center;
  flex: 1; /* Allow text to take available space */
}

/* Social Media Icons Container */
.social-media-icons {
  display: flex;
  gap: 10px; /* Space between icons */
}

/* Individual Social Media Icon Styling */
.social-icon {
  width: 30px; /* Icon size */
  height: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Hover Effect for Social Media Icons */
.social-icon:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.category-page {
  padding: 40px;
  background: linear-gradient(135deg, #f5f7fa, #e4ebf5);
  font-family: 'Arial', sans-serif;
  color: #333;
}

.category-header {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #2c3e50;
  position: relative; /* For positioning the underline */
  display: inline-block; /* To make the underline apply only to the text */
  overflow: hidden;
}

.category-header::after {
  content: ''; /* Create the underline */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0; /* Initially no width */
  height: 2px; /* Thickness of the underline */
  background-color: #3498db; /* Color of the underline */
  transition: width 0.3s ease; /* Smooth transition */
}

.category-header:hover {
  color: #3498db; /* Change to desired color on hover */
}

.category-header:hover::after {
  width: 100%; /* Full width underline on hover */
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.project-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image {
  width: 100%;
  max-height: 150px;
  object-fit:cover;
}

.project-name {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #2c3e50;
}

.project-page {
  padding: 20px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.project-title {
  font-size: 2rem;
  margin-bottom: 10px;
  opacity: 0;
  animation: slideDown 0.8s ease-in-out forwards;
  position: relative;
  transition: color 0.3s ease;
}

.project-title:hover {
  color: #3498db;
  /* Highlight color on hover */
}

.project-title:hover::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #3498db;
  /* Matches hover color */
  animation: underline 0.4s ease-in-out forwards;
}

.project-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  opacity: 0;
  animation: slideUp 0.8s ease-in-out 0.4s forwards;
  position: relative;
  transition: color 0.3s ease;
}

.project-description:hover {
  color: #2ecc71;
  /* Highlight color for description */
}

.project-description:hover::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #2ecc71;
  /* Matches hover color */
  animation: underline 0.4s ease-in-out forwards;
}

.project-images {
  display: flex;
  row-gap: 20px; 
  column-gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}

.project-images img {
  max-width: 100%;
  max-height: 350px;
  width: auto; 
  height: auto; 
  border-radius: 5px;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  opacity: 0;
  animation: fadeIn 1.2s ease-in-out 0.6s forwards;
}

.project-images img:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  filter: brightness(1.15);
}

/* Fade-in animation for the page */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Slide-down animation for the title */
@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Slide-up animation for the description */
@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Underline animation */
@keyframes underline {
  from {
    width: 0;
    opacity: 0;
  }

  to {
    width: 100%;
    opacity: 1;
  }
}
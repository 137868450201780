/* Loading screen container */
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    position: relative;
    flex-direction: column;
  }
  
  /* Rubik's Cube Container */
  .rubiks-cube {
    width: 100px;
    height: 100px;
    position: relative;
    transform-style: preserve-3d;
    animation: rotateCube 6s infinite linear; /* Duration of 6 seconds for 2 rotations */
  }
  
  .rubiks-face {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: #fff;
    border: 2px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rubiks-face .logo {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
  }
  
  /* Positioning each face of the Rubik's Cube */
  .front  { transform: rotateY(  0deg) translateZ(50px); }
  .right  { transform: rotateY( 90deg) translateZ(50px); }
  .back   { transform: rotateY(180deg) translateZ(50px); }
  .left   { transform: rotateY(-90deg) translateZ(50px); }
  .top    { transform: rotateX( 90deg) translateZ(50px); }
  .bottom { transform: rotateX(-90deg) translateZ(50px); }
  
  /* Keyframes for 3D rotation animation */
  @keyframes rotateCube {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(360deg); /* First full rotation */
    }
    100% {
      transform: rotateY(720deg); /* Second full rotation */
    }
  }
  
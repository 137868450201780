/* General Header Styling */
header {
  background-color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 70px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Wrapper */
.header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

/* Logo Section */
.logo-section {
  display: flex;
  align-items: center;
}

/* Ensure the logo remains relative to the header size */
.logo-image {
  height: 60%;
  /* Maintain proportion */
  max-height: 60px;
  /* Prevent it from growing too large */
  width: auto;
  /* Maintain aspect ratio */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.logo-image:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

/* Adjust content positioning to avoid overlap with the fixed header */
.content {
  margin-top: 25px;
  /* Add space for the header height */
}

/* If you are using padding instead of margin */
.content {
  padding-top: 45px;
  /* Add space for the header height */
}

/* Desktop Navigation (Always Visible on Large Screens) */
.desktop-nav {
  display: flex;
}

.desktop-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.desktop-nav ul li {
  margin-left: 20px;
  position: relative;
}

.desktop-nav ul li a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 0;
  display: inline-block;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Underline effect for links */
.desktop-nav ul li a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #0077b6;
  transition: width 0.3s ease-out;
}

/* On hover, the underline expands */
.desktop-nav ul li a:hover::after {
  width: 100%;
}

.desktop-nav ul li a:hover {
  color: #0077b6;
}

/* Hamburger Menu (Initially Hidden on Large Screens) */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 12px;
  z-index: 1100;
  transition: transform 0.5s ease;
  width: 30px;
  /* Set width slightly bigger for better visibility */
  height: 35px;
  /* Set height slightly bigger */
  justify-content: space-between;
  padding: 5px;
}

/* Hamburger Bars */
.bar {
  width: 100%;
  height: 5px;
  background-color: #333;
  transition: all 0.3s ease;
  border-radius: 5px;
}

/* New Hamburger Transformation Effect */
.bar.open:nth-child(1) {
  width: 150%;
  transform: translateY(12px) rotate(45deg);
  /* Moves first bar down and rotates */
}

.bar.open:nth-child(2) {
  opacity: 0;
  /* Fades out the middle bar */
}

.bar.open:nth-child(3) {
  width: 150%;
  transform: translateY(-8px) rotate(-45deg);
  /* Moves the third bar up and rotates */
}

/* Mobile Navigation (Initially Hidden) */
.mobile-nav {
  display: none;
}

/* Responsive Design */
@media (max-width: 768px) {

  /* Hide Desktop Nav */
  .desktop-nav {
    display: none;
  }

  /* Show Hamburger Menu */
  .hamburger {
    display: flex;
  }

  /* Mobile Navigation */
  .mobile-nav {
    display: none;
    position: fixed;
    top: 70px;
    right: 0;
    width: auto;
    /* Set width to auto */
    max-width: 250px;
    /* Restrict maximum width */
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    transition: transform 0.3s ease-in-out;
    border-radius: 10px 0 0 10px;
    /* Rounded corners on the left side */
    transform: translateX(100%);
    /* Initially hidden offscreen to the right */
  }

  .mobile-nav.active {
    display: flex;
    flex-direction: column;
    transform: translateX(0);
    /* Slide in from the right */
  }

  /* Navigation links within the mobile menu */
  .mobile-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    /* Remove margin around the list */
    text-align: right;
  }

  .mobile-nav ul li {
    margin: 10px 0;
    /* Smaller spacing between links */
  }

  .mobile-nav ul li a {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    /* Standard font size for better fit */
    font-weight: 500;
    /* Same font-weight as desktop */
    text-transform: uppercase;
    /* Maintain uppercase */
    letter-spacing: 1px;
    /* Consistent letter-spacing */
    padding: 8px 12px;
    /* Smaller padding for tighter fit */
    display: block;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border-radius: 5px;
  }

  .mobile-nav ul li a:hover {
    background-color: #0077b6;
    color: white;
    padding-left: 15px;
    transform: translateX(5px);
    /* Slight movement when hovered */
  }
}
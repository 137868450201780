/* src/index.css */

/* Resetting some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Helvetica', sans-serif;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  background-color: #f4f4f4; /* Light gray background */
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.hero-content {
  z-index: 10;
  color: #333; /* Dark text for visibility */
}

.hero h1 {
  font-size: 4rem; /* Large title */
  margin-bottom: 20px;
  color: #333;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #555;
}

.cta-btn {
  background-color: #ff9800; /* Bright call-to-action color */
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-btn:hover {
  background-color: #e68900; /* Slightly darker shade on hover */
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
}

footer p {
  font-size: 1rem;
  margin: 0;
}

/* Ensure responsiveness for smaller screens */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 3rem; /* Adjust title size for mobile */
  }

  .hero p {
    font-size: 1.2rem; /* Adjust paragraph size for mobile */
  }

  .cta-btn {
    font-size: 1rem;
    padding: 12px 25px; /* Adjust button size for mobile */
  }
}
